import { Helmet } from "react-helmet";
import { Component } from "react";
import Header from "../Header";
import VideoComponent from "../VideoComponent";
import ScrollingText from "../ScrollingText";
import Services from "../Services";
import AboutUs from "../AboutUs";
import ContactUs from "../ContactUs";
import FollowUs from "../FollowUs";
import "./index.css";

class Home extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Digital marketing company in VZM | Digital solutions for digital
            life
          </title>
          <meta
            name="description"
            content="Upgrade your company’s online presence with our professional digital marketing solutions in Vizianagaram. Get started today!"
          />
          <meta
            name="keywords"
            content="best digital marketing company in vizianagaram, seo, website designing, social media agency, digital marketing agency, top digital marketing agency in vizianagaram, graphic designing,best digital marketing agencies, best marketing agencies, online advertising companies, google marketing agency, digital advertising companies, best digital marketing companies, digital marketing for real estate, online business advertising, digital solutions for digital life"
          />
          <link
            rel="canonical"
            href="https://digitalsolutionsfordigitallife.com/digitalmarketingcompany"
          />
        </Helmet>
        <div className="bg-container" id="homeSection">
          <Header />
          <VideoComponent />
          <ScrollingText />
          <div id="servicesSection">
            <Services />
          </div>
          <div id="aboutSection">
            <AboutUs />
          </div>
          <div id="contactSection">
            <ContactUs />
            <FollowUs />
          </div>
        </div>
      </>
    );
  }
}
export default Home;
