import { Component } from "react";
import Header from "../Header";
import EachRequest from "../EachRequest";
import Cookies from "js-cookie";
import moment from "moment";
import "./index.css";

class Requests extends Component {
  state = {
    requestList: [],
    isLoading: false,
    error: null,
  };

  componentDidMount() {
    this.getRequestList();
  }

  getRequestList = async () => {
    this.setState({ isLoading: true });
    const url = "https://digital-life-backend.vercel.app/requests";
    const jwtToken = Cookies.get("jwt_token");
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const formatedData = data.map((each) => ({
        id: each._id,
        name: each.name,
        email: each.email,
        phone: each.phone,
        company: each.company,
        service: each.service,
        msg: each.msg,
        date: each.date,
      }));
      this.setState({ requestList: formatedData });
    } catch (error) {
      console.error(error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  deleteRequestFromDatabase = async (id) => {
    const url = `https://digital-life-backend.vercel.app/requests/${id}`;
    const options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt_token")}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to delete request");
      }
      const { requestList } = this.state;
      const updatedData = requestList.filter((each) => each.id !== id);
      this.setState({ requestList: updatedData });
    } catch (error) {
      console.log(error.message);
    }
  };

  //   renderSuccess = () => {
  //     const { requestList } = this.state;
  //     return (
  //       <div className="main-req">
  //         {requestList.length > 0 ? (
  //           <table className="requests-table">
  //             <thead>
  //               <tr>
  //                 <th>Date</th>
  //                 <th>Name</th>
  //                 <th>Email</th>
  //                 <th>Phone</th>
  //                 <th>Company</th>
  //                 <th>Service</th>
  //                 <th>Message</th>
  //                 <th>Actions</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {requestList.map((each) => (
  //                 <tr key={each.id}>
  //                   <td>{moment(each.date).format("DD MMM YY ddd")}</td>
  //                   <td>{each.name}</td>
  //                   <td>{each.email}</td>
  //                   <td>{each.phone}</td>
  //                   <td>{each.company}</td>
  //                   <td>{each.service}</td>
  //                   <td>{each.msg}</td>
  //                   <td>
  //                     <button
  //                       onClick={() => this.deleteRequestFromDatabase(each.id)}
  //                       className="delete-btn"
  //                     >
  //                       Delete
  //                     </button>
  //                   </td>
  //                 </tr>
  //               ))}
  //             </tbody>
  //           </table>
  //         ) : (
  //           <div className="noreq-cont">
  //             <img
  //               src="https://cdn3.iconfinder.com/data/icons/traveling-45/24/canceled-512.png"
  //               className="noreq-img"
  //               alt="no data"
  //             />
  //             <h1 className="noreq">No Service Requests</h1>
  //           </div>
  //         )}
  //       </div>
  //     );
  //   };
  renderSuccess = () => {
    const { requestList } = this.state;

    // Function to toggle message visibility
    const toggleMessage = (id) => {
      this.setState((prevState) => ({
        requestList: prevState.requestList.map((request) =>
          request.id === id
            ? { ...request, showFullMsg: !request.showFullMsg }
            : request
        ),
      }));
    };

    return (
      <div className="main-req">
        {requestList.length > 0 ? (
          <div className="card-container">
            {requestList.map((each) => (
              <div className="card" key={each.id}>
                <h3>{each.name}</h3>
                <p>
                  <strong>Date:</strong>{" "}
                  {moment.utc(each.date).format("DD MMM YY ddd, hh:mm A")}
                </p>
                <p>
                  <strong>Email:</strong> {each.email}
                </p>
                <p>
                  <strong>Phone:</strong> {each.phone}
                </p>
                <p>
                  <strong>Company:</strong> {each.company}
                </p>
                <p>
                  <strong>Service:</strong> {each.service}
                </p>

                <div
                  className={`msg-container ${each.showFullMsg ? "show" : ""}`}
                  onClick={() => toggleMessage(each.id)}
                >
                  <p>
                    <strong>Message:</strong> {each.msg}
                  </p>
                </div>

                <button
                  onClick={() => this.deleteRequestFromDatabase(each.id)}
                  className="delete-btn"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="noreq-cont">
            <img
              src="https://cdn3.iconfinder.com/data/icons/traveling-45/24/canceled-512.png"
              className="noreq-img"
              alt="no data"
            />
            <h1 className="noreq">No Service Requests</h1>
          </div>
        )}
      </div>
    );
  };

  renderFailure = () => {
    return (
      <div className="noreq-cont">
        <img
          src="https://cdn-icons-png.flaticon.com/512/6418/6418218.png"
          className="noreq-img"
          alt="no data"
        />
        <h1 className="noreq">
          You are not Authorised to access service requests
        </h1>
      </div>
    );
  };

  render() {
    const jwtToken = Cookies.get("jwt_token");
    const role = Cookies.get("role");

    return (
      <div className="bg-container" id="homeSection">
        <Header />
        {this.state.isLoading ? (
          <div className="loading-view">Loading...</div>
        ) : jwtToken && role === "admin" ? (
          this.renderSuccess()
        ) : (
          this.renderFailure()
        )}
      </div>
    );
  }
}

export default Requests;
